.csslogo {
    width: 80px;
}

.espanna {
    background-image: url('../img/paises/icons8-emoji-españa-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.estadosunidos {
    background-image: url('../img/paises/icons8-estados-unidos-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.ingles {
    background-image: url('../img/paises/icons8-inglés-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.marruecos {
    background-image: url('../img/paises/icons8-emoji-marruecos-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.rusia {
    background-image: url('../img/paises/icons8-rusia-emoji-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.india {
    background-image: url('../img/paises/icons8-emoji-india-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.pakistan {
    background-image: url('../img/paises/icons8-emojis-de-pakistán-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.armenio {
    background-image: url('../img/paises/icons8-emoji-armenio-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.georgia {
    background-image: url('../img/paises/icons8-georgia-emoji-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.rumania {
    background-image: url('../img/paises/icons8-rumania-emoji-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.ucrania {
    background-image: url('../img/paises/icons8-ucrania-emoji-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.china {
    background-image: url('../img/paises/icons8-emoji-china-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.portugal {
    background-image: url('../img/paises/icons8-portugal-emoji-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.albania {
    background-image: url('../img/paises/icons8-albania-emoji-48.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}
.wp {
    background-image: url('../img/proyecto/wp.png');
    background-repeat: no-repeat;
    background-size: 25px 30px;
    background-position: left;
}