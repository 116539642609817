.tonoazul1 {
  background-color: #001952;
}
.h1 {
  color: #d4ac0d;
}
.h3 {
  color: #d4ac0d;
  font-weight: 400;
  span {
    font-weight: bold;
    color: white;
  }
  padding: 0.6rem;
}

.dark-1 {
  font-size: 2rem;
  display: block;
  margin: 10px 10px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  text-shadow: 7px 4px 5px blue;
}
.dark-2 {
  color: #f1f5ee;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: bolder;
  text-shadow: 7px 4px 5px blue;
  text-shadow: -2px -2px 0px blue, 2px -2px 0px #5f1c1c, -2px 2px 0px #26697e,
    2px 2px 0px #cc0000;
}
.dark-3 {
  color: #0e0e11;
  font-size: 2rem;
  display: block;
  margin: 10px 10px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  text-shadow: 7px 4px 5px rgb(226, 226, 238), -1px -1px 0px blue,
    -1px 2px 0px #26697e;
}
.dark-4 {
  color: #f1f5ee;
  /*    font-size: 1.2rem;*/
  font-style: italic;
  font-weight: bolder;
  /*    text-shadow: -2px -2px 0px blue, -2px 2px 0px #26697e;*/
}
/*
.div-background-about {
  background: url("../img/about/about2ok.jpg");
}
.div-background-agency {
  background: url("../img/agency/agency4ok.jpg");
}
.div-background-objetives {
  background: url("../img/objetives/objetives1ok.jpg");
}
*/
.box {
  border: 1px solid darkblue;
  height: 300px;
  width: 250px;
}

.container {
  position: relative;
  width: 50%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #008CBA;
  overflow: hidden;
  width: 100%;
  height:0;
  transition: .5s ease;
}

.container:hover .overlay {
  bottom: 0;
  height: 100%;
}

.text {
  white-space: nowrap; 
  color: white;
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}